import React from 'react'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from "../components/PageTitle";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Img from 'gatsby-image'
import Name from '../components/Item/Name'
import Body from '../components/Item/Body'
import Information from '../components/Item/Information'
import Slash from '../components/Slash/Slash'
import SimpleSlider from '../components/Slider'
import ItemWrapper from '../components/Item/ItemWrapper'
import SEO from '../components/SEO'
import { startCase } from 'lodash'

const ActivityTemplate = ({ data, pageContext }) => {
  const {
    title,
    startDate,
    endDate,
    introduction,
    description,
    thumbnail,
    images,
    status,
    locationAddress,
    locationLink,
    locationName,
    slug
  } = data.contentfulActivity

  const { basePath } = pageContext
  const itemImages = images ? images : []

  let ogImage
  try {
    ogImage = thumbnail.file.url
  } catch (error) {
    ogImage = null
  }

  const style = {
    transition: "opacity 500ms ease 3s"
  }

  return (
    <Layout>
      <SEO title={title} image={ogImage} />
      <Container>
        <PageTitle>{status}</PageTitle>
        <Slash />
        <ItemWrapper>
          <Img
            fluid={thumbnail.fluid}
            alt={thumbnail.title}
            backgroundColor={'#eeeeee'}
            style={style}
          />
          <Name>{title}</Name>
          <Information
            startDate={startDate ? startDate : null}
            endDate={endDate ? endDate : null}
            status={status}
          >
            {locationName && locationAddress && locationLink && (
              <>
                <br />{""}<br />
                <a href={locationLink} target="_blank" rel="noopener noreferrer" style={{ fontSize: "0.9em", lineHeight: "1.5", fontWeight: 400 }} >
                  {locationName}
                </a>
                <br />
                <p style={{ fontSize: "0.9em", lineHeight: "1.5" }}>
                  {locationAddress}
                </p>
              </>
            )}
          </Information>
          <Body>
            {documentToReactComponents(introduction.json)}
          </Body>
          <SimpleSlider>
            {
              itemImages.map((image) =>
                <Img
                  fluid={image.fluid}
                  alt={image.title}
                  backgroundColor={'#eeeeee'}
                />
              )
            }
          </SimpleSlider>
          {description && (
            <Body>
              {documentToReactComponents(description.json)}
            </Body>
          )}
          {(slug == "wallasan-mail") && (
            <p style={{ marginBottom: "4em" }}>
              【申し込みフォーム】<br />{""}<br />
              <a href="https://forms.gle/axMJpY67nPKRpw7QA" target="_blank" rel="noopener noreferrer" style={{ color: "#121212" }}>
                https://forms.gle/axMJpY67nPKRpw7QA
              </a>
            </p>
          )}
        </ItemWrapper>
      </Container>
    </Layout >
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulActivity(slug: { eq: $slug }) {
      slug
      title
      startDate
      endDate
      introduction {
        json
      }
      description {
        json
      }
      thumbnail {
        fluid(maxWidth: 800, maxHeight: 566, resizingBehavior: PAD) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        title
        file {
          url
        }
      }
      images {
        fluid(maxWidth: 800, maxHeight: 566, resizingBehavior: PAD) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        title
      }
      status
      locationAddress
      locationLink
      locationName
    }
  }
`

export default ActivityTemplate
