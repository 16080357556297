import React from 'react'
import styled from '@emotion/styled'

// -------------------------------------------------
// アイテムの名前、タイトル
// -------------------------------------------------

const ItemName = styled.h2`
  font-size: 1.5em;
  font-weight: 400;
  line-height: 2;
  padding: ${props => props.padding ? props.padding : "1.5em 0"};
`

const Name = props => {
  return <ItemName padding={props.padding}>{props.children}</ItemName>
}

export default Name
